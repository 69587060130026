<template>
  <v-main style="background-color: #fff" class="mt-16">
    <v-container class="my-16">
      <v-row class="justify-space-between">
        <h4 v-if="!editing && location != null">
          {{ location.name }}
          <v-icon @click="editing = !editing">mdi-pencil</v-icon>
        </h4>
        <v-text-field
          v-else
          class="input-sm"
          v-model="locationName"
          @change="_changeItem(locationName, 'name')"
        />
      </v-row>
      <v-row class="justify-start mt-6">
        <label for="avatarUpload" class="me-4">
          <v-card
            min-width="300"
            min-height="170"
            outlined
            class="mr-8 d-flex align-center justify-center"
            style="cursor:pointer"
          >
            <div
              class="text-center"
              style="font-size: 12pt; font-weight: 700"
              align="center"
            >
              <v-icon color="black">mdi-plus-circle</v-icon>
              Зураг оруулаx ({{ imageFiles.length }})
            </div>
            <input
              ref="file"
              type="file"
              id="avatarUpload"
              class="d-none drop"
              @change="uploadFile"
            />
          </v-card>
        </label>
        <v-sheet outlined elevation="0" max-width="800" max-height="300">
          <v-slide-group class="pa-4" multiple show-arrows>
            <v-slide-item v-for="(image, n) in imageFiles" :key="n">
              <v-card class="ma-4" height="100" width="100">
                <v-row class="fill-height" align="center" justify="center">
                  <v-img
                    :src="image"
                    :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-row>
              </v-card>
            </v-slide-item>
            <v-card
              v-if="imageFiles && imageFiles.length == 0"
              class="ma-4"
              height="100"
              width="100"
            >
              <v-row class="fill-height" align="center" justify="center">
                <v-scale-transition> </v-scale-transition>
              </v-row>
            </v-card>
          </v-slide-group>
        </v-sheet>
      </v-row>
      <v-row>
        <v-card class="mt-4 pa-0 ma-0" width="100%">
          <v-card-text>
            <v-row justify="space-between">
              <v-col cols="12" md="3" lg="3">
                <h4 >{{ selectedLng }}, {{ selectedLat }}</h4>
              </v-col>

              <v-col cols="12" md="3" lg="3">
                <v-select class="px-10" :items="cities" v-model="selectedCity">
                  <template v-slot:item="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template></v-select
                >
              </v-col>
              <v-col cols="12" md="3" lg="3">
                <v-select class="px-10" :items="cities">
                  <template v-slot:item="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template></v-select
                >
              </v-col>
              <v-col cols="12" md="3" lg="3">
                <v-btn
                  v-if="
                    (imageFiles && imageFiles.length > 0) ||
                    (selectedLng != null && selectedLat != null)
                  "
                  color="#000"
                  style="color: white !important"
                  @click="_save"
                >
                  Xадгалаx
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-mapbox
            access-token="pk.eyJ1IjoiZ3JhbmRpdHh4ayIsImEiOiJja25mcDYxdHYycjRnMzFwaGx0ZWVwNGhpIn0.EBks4tBwxFPv66eqLJfTWQ"
            map-style="mapbox://styles/mapbox/satellite-streets-v11"
            :center="[106.9057, 47.8864]"
            :zoom="4.5"
            id="map"
            ref="map"
          ></v-mapbox>
        </v-card>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
// Utilities
import { get } from "vuex-pathify";
import TripCard from "./TripCard.vue";
import Vue from "vue";
import firebase from "firebase/app";
const fb = require("../../firebaseConfig.js");
import mapboxgl from "mapbox-gl";
export default {
  data: () => ({
    location: null,
    selectedCity: null,
    editing: false,
    title: "dfsdfasdf",
    uploading: false,
    uploadPercent: null,
    imageFiles: [],
    selectedLng: null,
    selectedLat: null,
    cities: null,
    locationName: null,
    lastClickedPoint: null
  }),
  components: {
    TripCard,
  },
  computed: {},
  created() {
    if (this.locationId != null)
      fb.db
        .doc(this.locationId)
        .get()
        .then((doc) => {
          let location = doc.data();
          location.id = doc.id;
          location.ref = doc.ref;
          this.location = location;
          console.log(this.location);
          this.locationName = this.location.name;
        });
    fb.db
      .collection("countries/Mongolia/cities")
      .orderBy("index", "asc")
      .onSnapshot((querySnapshot) => {
        this.cities = [];
        querySnapshot.forEach((doc) => {
          let city = doc.data();
          city.id = doc.id;
          city.ref = doc.ref;

          this.cities.push(city);
        });
      });
  },
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },

  mounted() {
    let map = this.$refs.map.map;
    mapboxgl.accessToken =
      "pk.eyJ1IjoiZ3JhbmRpdHh4ayIsImEiOiJja25mcDYxdHYycjRnMzFwaGx0ZWVwNGhpIn0.EBks4tBwxFPv66eqLJfTWQ";

    map.on("load", () => {
      var _this = this;

      // async function addItem(lng, lat) {
      //   console.log(lng, lat);
      //   const location = new firebase.firestore.GeoPoint(
      //     Number(lat),
      //     Number(lng)
      //   );

      //   await db.collection("mapcoords").add({
      //     name: "dfsdfsdf",
      //     createdAt: new Date(),
      //     status: "notcompleted",
      //     coords: location,
      //   });
      // }

      map.on("click", function (e) {
        if(_this.lastClickedPoint!=null)
          _this.lastClickedPoint.remove()
        _this.selectedLng = e.lngLat.lng;
        _this.selectedLat = e.lngLat.lat;
        _this.lastClickedPoint = new mapboxgl.Marker().setLngLat(e.lngLat).addTo(map);
      });
    });
  },
  methods: {
    async _save() {
      const geoPoint = new firebase.firestore.GeoPoint(
        Number(this.selectedLat),
        Number(this.selectedLng)
      );
      console.log(this.location.ref.path);
      console.log(this.location);
      console.log(this.selectedCity);
      await this.location.ref
        .update({
          name: this.location.name,
          createdAt: new Date(),
          geopoint: geoPoint,
          selectedCity:
            this.selectedCity != null ? this.selectedCity.ref : null,
          selectedCityName:
            this.selectedCity != null ? this.selectedCity.name : null,
          images:
            this.imageFiles != null && this.imageFiles.length > 0
              ? this.imageFiles.map((x) => x)
              : null,
        })
        .then(() => {
          console.log("success");
        });
    },
    _changeItem(locationName, key) {
      if (key == "name") {
        this.location.name = locationName;
      }
      this.location.ref.update(this.location).then(() => {
        this.editing = !this.editing;
        console.log("success");
      });
    },
    uploadFile() {
      var _this = this;
      var files = this.$refs.file.files;
      if (files != null && files.length > 0) {
        var uploadTask = fb.storageRef.child("testtest").put(files[0]);
        _this.uploading = true;
        _this.uploadPercent = 0;
        uploadTask.on(
          fb.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            _this.uploadPercent = progress;
          },
          function (error) {
            switch (error.code) {
              case "storage/unauthorized":
                break;

              case "storage/canceled":
                break;

              case "storage/unknown":
                break;
            }
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                console.log(downloadURL);
                _this.imageFiles.push(downloadURL);
                // const fileObject = {
                //   fileName: 'testtest',
                //   fileUrl: downloadURL,
                //   uploadedAt: now,
                // };
                // fb.db.collection('tmp').doc().set({
                //   images: fb.firestore.FieldValue.arrayUnion(fileObject),
                // });

                // if (_this.object != null) {
                //   if (_this.object.images == null) _this.object.images = [];
                //   _this.object.images.push(fileObject);
                // }
                // eslint-disable-next-line no-sequences
                // (_this.uploading === false), (_this.uploadPercent === null)

                _this.$refs.file.value = "";

                if (!/safari/i.test(navigator.userAgent)) {
                  _this.$refs.file.type = "";
                  _this.$refs.file.type = "file";
                }
              });
          }
        );
      }
    },
  },
};
</script>
<style>
.mapboxgl-map {
  overflow-x: hidden;
  height: 60vh;
}
</style>